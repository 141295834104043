export class Role {
    name:string='';
    code:number=0;
    codeStr:string='';
    initPath:string='';
    constructor(name, code, codeStr) {
        this.name = name;
        this.code = code;
        this.codeStr = codeStr;
    }
    GetName(){
        return this.name
    }
    GetCode(){
        return this.code
    }
    GetCodeStr(){
        return this.codeStr
    }

}

// admin system multitenant =  kelola operator parkir (tenant)
export var SUPERADMIN = new Role('Super', 3, '3');

// spg
export var ADMIN = new Role('Admin', 10, '10');

// only = view all dashboard
export var VIEWER = new Role('Viewer', 20, '20');

// only = view dashboard transaction
export var VIEWERPSM = new Role('ViewerPSM', 21, '21');

// operator = setting space, jukir, match jukir ke space
export var OPERATOR = new Role('Operator', 30, '30')

// edc admin= setting EDC
export var EDCADMIN = new Role('EDC Admin', 14, '14');

export var JUKIR = new Role('Jukir', 15, '15');

export var USER = new Role('User', 99, '99');
