import { Routes } from '@angular/router';

import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/authentication/login', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: './dashboards/dashboard.module#DashboardModule'
      },
      {
        path: 'spc',
        loadChildren: './spc/spc.module#SpcModule'
      },
      {
        path: 'bss',
        loadChildren: './bss/bss.module#BSSModule'
      },
      {
        path: 'usr',
        loadChildren: './usr/usr.module#UsrModule'
      },
      {
        path: 'report',
        loadChildren: './report/report.module#ReportModule'
      },
      {
        path: 'track',
        loadChildren: './track/track.module#TrackModule'
      },
      {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule'
      },
      {
        path: 'subscription',
        loadChildren: './subscription/subscription.module#SubscriptionModule'
      },
      // {
      //   path: 'starter',
      //   loadChildren: './starter/starter.module#StarterModule'
      // },
      // {
      //   path: 'component',
      //   loadChildren: './component/component.module#ComponentsModule'
      // },
      // { path: 'cards', loadChildren: './cards/cards.module#CardsModule' },
      // { path: 'icons', loadChildren: './icons/icons.module#IconsModule' },
      // { path: 'forms', loadChildren: './form/forms.module#FormModule' },
      // { path: 'tables', loadChildren: './table/tables.module#TablesModule' },
      // { path: 'charts', loadChildren: './charts/charts.module#ChartModule' },
      // {
      //   path: 'widgets',
      //   loadChildren: './widgets/widgets.module#WidgetsModule'
      // },
      // { path: 'ecom', loadChildren: './ecommerce/ecom.module#EcomModule' },
      // {
      //   path: 'timeline',
      //   loadChildren: './timeline/timeline.module#TimelineModule'
      // },
      // {
      //   path: 'extra-component',
      //   loadChildren:
      //     './extra-component/extra-component.module#ExtraComponentModule'
      // },
      { path: 'apps', loadChildren: './apps/apps.module#AppsModule' },
      { path: 'apps/email', loadChildren: './apps/email/mail.module#MailModule' },
      { path: 'maps', loadChildren: './maps/maps.module#MapsModule' },
      {
        path: 'sample-pages',
        loadChildren: './sample-pages/sample-pages.module#SamplePagesModule'
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/authentication/404'
  }
];
